import React, {useState, useEffect, useRef} from 'react';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,TextField
} from '@mui/material';
import DateRangeSelector from '../../../components/date/DateRangeSelector';
import StatisticsCheckbox from '../../../components/date/StatisticsCheckbox';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SelectMulti from '../../../components/select/SelectMulti';
import {fetchMobileSCOFields, fetchMobileSCOKv7, fetchMobileSCOKv18} from '../../../API/PixalaleReport';
import useApi from '../../../hooks/useApi';
import Picker from 'react-month-picker'
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';
import axios from "axios";

function MobileOptionsForm({onSubmit}) {
    const [selectedKv7, setSelectedKv7] = useState([]);
    const [selectedKv18, setSelectedKv18] = useState([]);

    const [showKv7, setShowKv7] = useState(false);
    const [showKv18, setShowKv18] = useState(false);
    const [showComplete, setShowComplete] = useState(false);
    const [showChainLength, setShowChainLength] = useState(false);

    const {data: kv7, loading: loadingKv7} = useApi(fetchMobileSCOKv7);
    const {data: kv18, loading: loadingKv18} = useApi(fetchMobileSCOKv18);
    const {data: statistics, loading: loadingStats} = useApi(fetchMobileSCOFields);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate = new Date(yesterday.toISOString().split('T')[0]);

    const [options, setOptions] = useState({
        start_date: formattedDate,
        end_date: formattedDate,
        stat: [],
        kv7: [],
        kv18: [],
        complete: [],
        chainLength: "",
    });

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });
    const handleDateChange = (event) => {
        const endDate = new Date(event.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    const [showCalendar, setShowCalendar] = useState(false);

    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    useEffect(() => {
        const storedValue = Cookies.get('HopsMobile');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            setOptions(parsedValue);
        }

    }, []);

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stat = [...options.stat];

        if (checked) {
            stat.push(name);
        } else {
            stat = stat.filter((satat) => satat !== name);
        }

        setOptions({...options, stat});
    };

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            kv7: selectedKv7,
        }));
    }, [selectedKv7]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            kv18: selectedKv18,
        }));
    }, [selectedKv18]);


    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%", margin: '0px 0 25px auto'}}>
                            <Grid item xs={12} md={12}>
                                {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                            </Grid>
                            {statistics?.map((statistic) => (
                                <StatisticsCheckbox
                                    key={statistic.key}
                                    statistic={statistic}
                                    checked={options.stat.includes(statistic.key)}
                                    onChange={handleCheckboxChangeStat}
                                />
                            ))}
                        </Grid>

                        <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                            <Grid item xs={12} md={12}>
                                <strong>Filters</strong>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showChainLength}
                                                onChange={() => setShowChainLength(!showChainLength)}
                                                name="chainLength"
                                            />
                                        }
                                        label="Chain Length"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showComplete}
                                                onChange={() => setShowComplete(!showComplete)}
                                                name="complete"
                                            />
                                        }
                                        label="Path Complete"
                                    />
                                    {Object.keys(kv18).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showKv18}
                                                onChange={() => setShowKv18(!showKv18)}
                                                name="kv18"
                                            />
                                        }
                                        label="App ID"
                                    />) : ''}

                                    {Object.keys(kv7).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showKv7}
                                                onChange={() => setShowKv7(!showKv7)}
                                                name="kv7"
                                            />
                                        }
                                        label="Seller ID"
                                    />) : ''}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{marginTop: '10px',}}>
                                <Grid item xs={6}>
                                    {showChainLength && (
                                        <TextField
                                            fullWidth
                                            label="Chain Length more than"
                                            type="number"
                                            variant="outlined"
                                            value={options.chainLength || ''}
                                            onChange={(e) =>
                                                setOptions((prevOptions) => ({
                                                    ...prevOptions,
                                                    chainLength: e.target.value,
                                                }))
                                            }

                                        />
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    {showKv18 && (
                                        <SelectMulti
                                            label="App ID"
                                            items={kv18}
                                            selectedItems={selectedKv18}
                                            onChange={(event) => setSelectedKv18(event.target.value)}
                                            itemLabel="value"
                                            itemKey="value"
                                            itemValue="value"
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    {showKv7 && (
                                        <SelectMulti
                                            label="Seller ID"
                                            items={kv7}
                                            selectedItems={selectedKv7}
                                            onChange={(event) => setSelectedKv7(event.target.value)}
                                            itemLabel="value"
                                            itemKey="value"
                                            itemValue="value"
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    {showComplete && (
                                        <SelectMulti
                                            label="Path Complete"
                                            items={[
                                                {value: 'no'},
                                                {value: 'yes'}
                                            ]}
                                            selectedItems={options.complete}
                                            onChange={(event) =>
                                                setOptions((prevOptions) => ({
                                                    ...prevOptions,
                                                    complete: event.target.value
                                                }))
                                            }
                                            itemLabel="value"
                                            itemKey="value"
                                            itemValue="value"
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <DateRangeSelector
                    initialRange={{startDate: formattedDate, endDate: formattedDate, key: 'selection'}}
                    onRangeChange={handleDateChange}
                />
            </Grid>
        </Grid>
    );
}

export default MobileOptionsForm;
