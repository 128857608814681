import {fetchDataFromApi} from './apiHelper';

export const fetchDataFields = () => fetchDataFromApi('post', '/api/pixalate/getdatafields');
export const fetchStatisticFields = () => fetchDataFromApi('post', '/api/pixalate/getstatisticfields');
export const fetchSellers = () => fetchDataFromApi('post', '/api/pixalate/getsellers');
export const fetchBundles = () => fetchDataFromApi('post', '/api/pixalate/getbundles');
export const fetchPlatforms = () => fetchDataFromApi('post', '/api/pixalate/getplatform');
export const fetchPublishers = () => fetchDataFromApi('post', '/api/pixalate/getpublishers');

export const fetchMobileSCOFields = () => fetchDataFromApi('post', '/api/pixalate/sco-mobile/fields');
export const fetchMobileSCOKv7 = () => fetchDataFromApi('post', '/api/pixalate/sco-mobile/kv7');
export const fetchMobileSCOKv18 = () => fetchDataFromApi('post', '/api/pixalate/sco-mobile/kv18');